<template>
  <v-btn
    class="mx-auto mt-6"
    size="large"
    min-width="70%"
    :color="hasPhoto ? 'gray' : 'success'"
    :variant="hasPhoto ? 'tonal' : 'flat'"
    block
    @click="doAction"
  >
    {{ displayText }}
  </v-btn>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVisitStore } from '@/store/visit';

const emit =  defineEmits<{
  (e: 'resetPhoto'): void,
  (e: 'takePhoto'): void}
>();

const visitStore = useVisitStore();
const { t } = useI18n();
const hasPhoto = computed(() => visitStore.photo.value !== undefined);

const doAction = () => {
  if (hasPhoto.value) {
    emit('resetPhoto');
    return;
  }
  emit('takePhoto');
};

const displayText = computed(
  () => {
    if (hasPhoto.value) {
      return t('reTakePhoto');
    }
    return t('takePhoto');
  },
);
</script>

